var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-8"
  }, [_c('v-menu', {
    attrs: {
      "value": _vm.contextMenu.item,
      "offset-y": "",
      "absolute": "",
      "position-x": _vm.contextMenu.x,
      "position-y": _vm.contextMenu.y
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openNewTab(_vm.contextMenu.item);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-4 primary--text"
  }, [_vm._v("mdi-open-in-new")]), _vm._v("Open in new tab")], 1)], 1)], 1), _c('v-card', {
    staticClass: "rounded-lg"
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_c('v-text-field', {
    staticClass: "mr-4",
    staticStyle: {
      "max-width": "400px"
    },
    attrs: {
      "placeholder": "Filter...",
      "append-icon": "mdi-magnify",
      "dark": "",
      "dense": "",
      "outlined": "",
      "rounded": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('v-chip-group', {
    attrs: {
      "outlind": "",
      "multiple": "",
      "mandatory": "",
      "rounded": "",
      "dark": ""
    },
    model: {
      value: _vm.filterType,
      callback: function callback($$v) {
        _vm.filterType = $$v;
      },
      expression: "filterType"
    }
  }, [_c('v-chip', {
    attrs: {
      "filter": "",
      "small": "",
      "value": "customer"
    }
  }, [_vm._v("Cust/Acc")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "small": "",
      "value": "address"
    }
  }, [_vm._v("Address")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": "",
      "title": "Closed Jobs"
    },
    on: {
      "click": function click($event) {
        _vm.closed = !_vm.closed;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.closed ? "white" : "grey lighten-1"
    }
  }, [_vm._v("mdi-archive" + _vm._s(_vm.closed ? '-eye' : ''))])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": _vm.clickAddJob
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": _vm.printJobs
    }
  }, [_c('v-icon', [_vm._v("mdi-printer")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "jobTable",
    attrs: {
      "loading": _vm.loading,
      "dense": "",
      "hide-default-footer": "",
      "items-per-page": -1,
      "items": _vm.jobs,
      "item-key": "xid",
      "headers": _vm.headers,
      "options": _vm.options,
      "custom-sort": function customSort(items) {
        return items;
      }
    },
    on: {
      "click:row": _vm.clickJob,
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      },
      "contextmenu:row": _vm.rightClickJob
    },
    scopedSlots: _vm._u([{
      key: "item.number",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(_vm._s(item.number))])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(item.description))])])];
      }
    }, {
      key: "item.date_promised",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.date_promised ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                attrs = _ref4.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(_vm._s(_vm.moment(item.date_promised).format('MMM D, Y')))])];
            }
          }], null, true)
        }, [_c('span', {
          staticClass: "font-italic text-caption"
        }, [_vm._v(_vm._s(_vm.moment(item.date_promised).from()))])]) : _vm._e()];
      }
    }, {
      key: "item.date_enquired",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.date_enquired ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                attrs = _ref6.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(_vm._s(_vm.moment(item.date_enquired).format('MMM D, Y')))])];
            }
          }], null, true)
        }, [_c('span', {
          staticClass: "font-italic text-caption"
        }, [_vm._v(_vm._s(_vm.moment(item.date_enquired).from()))])]) : _vm._e()];
      }
    }, {
      key: "item.date_accepted",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.date_accepted ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on,
                attrs = _ref8.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(_vm._s(_vm.moment(item.date_accepted).format('MMM D, Y')))])];
            }
          }], null, true)
        }, [_c('span', {
          staticClass: "font-italic text-caption"
        }, [_vm._v(_vm._s(_vm.moment(item.date_accepted).from()))])]) : _vm._e()];
      }
    }, {
      key: "item.customer.name",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.customer ? _c('div', [_vm._v(_vm._s(item.customer.name))]) : _vm._e(), item.customer_acc ? _c('div', {
          staticClass: "text-caption font-italic"
        }, [_vm._v(_vm._s(item.customer_acc))]) : _c('span', {
          staticClass: "text-caption font-italic"
        }, [_vm._v("No customer")])];
      }
    }, {
      key: "item.address",
      fn: function fn(_ref10) {
        var value = _ref10.value;
        return [(value ? value.line_1 : false) ? _c('span', [_vm._v(" " + _vm._s([value.line_1, value.line_2, value.line3].filter(function (t) {
          return t;
        }).join(','))), _c('br'), _vm._v(" " + _vm._s([value.suburb, value.state, value.postcode].filter(function (t) {
          return t;
        }).join(' ')) + " ")]) : _c('span', {
          staticClass: "text-caption font-italic"
        }, [_vm._v("No Address")])];
      }
    }])
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.paginate_pages,
      "total-visible": 15
    },
    on: {
      "input": _vm.onPaginate
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }