<template>
  <v-container class='pt-8'>

    <v-menu :value="contextMenu.item" offset-y absolute :position-x="contextMenu.x" :position-y="contextMenu.y" >
      <v-list dense>
        <v-list-item link @click="openNewTab(contextMenu.item)"><v-icon class="mr-4 primary--text">mdi-open-in-new</v-icon>Open in new tab</v-list-item>
      </v-list>
    </v-menu>

    <v-card class='rounded-lg'>

      <v-card-title class='primary white--text'>
        <v-text-field
          placeholder='Filter...' append-icon='mdi-magnify'
          dark dense outlined rounded hide-details clearable
          class='mr-4' style='max-width:400px;'
          v-model='filter'
        />
        <v-chip-group v-model='filterType' outlind multiple mandatory rounded dark>
          <v-chip filter small value='customer'>Cust/Acc</v-chip>
          <v-chip filter small value='address'>Address</v-chip>
        </v-chip-group>
        <v-btn fab small text dark @click='closed = !closed' title='Closed Jobs'>
          <v-icon :color='closed?"white":"grey lighten-1"'>mdi-archive{{closed?'-eye':''}}</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn fab small text dark @click='clickAddJob'><v-icon>mdi-plus</v-icon></v-btn>
        <v-btn fab small text dark @click='printJobs'><v-icon>mdi-printer</v-icon></v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols=12 class='pb-0'>

          </v-col>
          <v-col cols=12 class='py-0'>
            <v-data-table
              :loading='loading'
              dense hide-default-footer
              :items-per-page="-1"
              class='jobTable'
              :items='jobs'
              item-key='xid'
              :headers='headers'
              @click:row='clickJob'
              :options.sync='options'
              :custom-sort="items=>items"
              @contextmenu:row="rightClickJob"
            >
              <template v-slot:item.number="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator='{on,attrs}'>
                    <span v-on='on' v-bind='attrs'>{{item.number}}</span>
                  </template>
                  <span>{{item.description}}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.date_promised="{item}">
                <v-tooltip v-if='item.date_promised' bottom>
                  <template v-slot:activator='{on,attrs}'>
                    <span v-on='on' v-bind='attrs'>{{moment(item.date_promised).format('MMM D, Y')}}</span>
                  </template>
                  <span class='font-italic text-caption'>{{moment(item.date_promised).from()}}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.date_enquired="{item}">
                <v-tooltip v-if='item.date_enquired' bottom>
                  <template v-slot:activator='{on,attrs}'>
                    <span v-on='on' v-bind='attrs'>{{moment(item.date_enquired).format('MMM D, Y')}}</span>
                  </template>
                  <span class='font-italic text-caption'>{{moment(item.date_enquired).from()}}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.date_accepted="{item}">
                <v-tooltip v-if='item.date_accepted' bottom>
                  <template v-slot:activator='{on,attrs}'>
                    <span v-on='on' v-bind='attrs'>{{moment(item.date_accepted).format('MMM D, Y')}}</span>
                  </template>
                  <span class='font-italic text-caption'>{{moment(item.date_accepted).from()}}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.customer.name="{item}">
                <div v-if='item.customer'>{{item.customer.name}}</div>
                <div v-if='item.customer_acc' class='text-caption font-italic'>{{item.customer_acc}}</div>
                <span v-else class='text-caption font-italic'>No customer</span>
              </template>
              <template v-slot:item.address="{value}">
                <span v-if='value?(value.line_1):false'>
                  {{[value.line_1, value.line_2, value.line3].filter(t=>t).join(',')}}<br />
                  {{[value.suburb, value.state, value.postcode].filter(t=>t).join(' ')}}
                </span>
                <span v-else class='text-caption font-italic'>No Address</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12 class='' style=''>
            <v-pagination v-model="page" :length="paginate_pages" :total-visible='15' @input='onPaginate' />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    

  </v-container>
</template>

<style lang="scss">
.jobTable{
  tbody td:hover{ cursor: pointer; }
}
.v-main{ overflow-y: auto; }
</style>


<script>
import { mapGetters,  mapActions } from 'vuex';
import PrintJS from 'print-js';
import Moment from 'moment';

export default {
  name: 'Jobs',
  components: {
  },
  props: {
    stageId: { type: String, required: false, default: null },
  },
  data(){ return {
    loading: false,
    jobs: [],
    closed: false,
    paginate_total: 0,
    paginate_pages: 0,
    page: 1,
    filter: '',
    filterType: ["customer", "address"],
    options: null,
    contextMenu: {
      item: null,
      x: null,
      y: null,
    }
  }},
  computed:{
    ...mapGetters("UI", ["JOB_STAGES"]),
    stage(){ return this.JOB_STAGES.find(s => s.xid == this.stageId) },
    headers(){
      let headers = [
        { text: "Job", value: 'number', sortable: true, },
        { text: "Customer", value: 'customer.name', sortable: false, },
        { text: "Address", value: 'address', sortable: false, },
        { text: "Enquired", value: 'date_enquired', sortable: true, },
        { text: "Accepted", value: 'date_accepted', sortable: true, },
        { text: "Forecast", value: 'date_promised', sortable: true, }
      ];
      if(!this.stage) headers.push({ text: "Stage", value: 'stage.title', sortable: false, });
      headers = [...headers,
        { text: "Billing", value: 'billing_stage.label', sortable: false, },
      ];
      return headers;
    },
    sortBy(){ return this.options?.sortBy[0] ?? null; },
    sortDesc(){ return this.options?.sortDesc[0] ?? false; },
  },
  watch: {
    $route: { handler(){ this.SET_TITLE(this.stage ? `Jobs - ${this.stage.title}` : "All Jobs"); }, immediate: true },
    stageId(){ this.page = 1; this.refreshJobs(); }, //filter changed via menu
    closed(){ this.page = 1; this.refreshJobs(); },
    filter(){ this.page = 1; this.refreshJobs(true); },
    filterType(){ this.page = 1; this.refreshJobs(true); },
    sortBy(){ this.page = 1; this.refreshJobs(); },
    sortDesc(){ this.page = 1; this.refreshJobs(); }
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE", "CONFIRM", "NAVIGATE"]),
    ...mapActions("API", ["API"]),

    moment(m){ return new Moment(m); },

    refreshJobs(debounce){
      this.loading = true;
      let autosave = debounce ? 500 : null;
      let data = {
        stage: this.stageId, closed: this.closed,
        filter: this.filter, filterType: this.filterType,
        sortBy: this.sortBy, sortDesc: this.sortDesc,
      };
      return this.API({ method: 'POST', endpoint: `jobs/filter?page=${this.page}`, data, autosave })
      .then(results => {
        this.jobs = results.data;
        this.paginate_total = results.total;
        this.paginate_pages = results.last_page;
      })
      .finally(() => { this.loading = false; });
    },

    onPaginate(){
      this.refreshJobs();
      this.$parent.$el.scrollTop = 0;
    },

    clickAddJob(){
      this.CONFIRM({ title: "Confirm", message: 'Create a new job?' })
      .then(() => {
        this.loading = true;
        this.API({ method: 'POST', endpoint: 'jobs/create' })
        .then(this.clickJob)
        .finally(() => { this.loading = false; });
      });
    },

    clickJob(job){
      this.NAVIGATE(`./${this.stageId}/${job.xid}`);
    },

    printJobs(){
      this.API({ endpoint: `stages/${this.stageId}/print` })
      .then(printable => {
        PrintJS({ printable, type: 'pdf', base64: true });
      });
    },

    rightClickJob(e, row){
      let job = row.item;
      this.contextMenu = { ...this.contextMenu,
        item: job,
        x: e.clientX, y: e.clientY
      };
      e.preventDefault();
    },

    openNewTab(job){
      window.open(`./${this.stageId}/${job.xid}`, '_blank');
    }

  },
  mounted(){
    this.refreshJobs();
  }
}
</script>
